import Amplify from '@aws-amplify/core'
import { LocationProps } from '@reach/router'
import { graphql, navigate } from 'gatsby'
import { TFunction } from 'i18next'
import React, { useEffect, useState } from 'react'
import { withTranslation } from 'react-i18next'
import { isUserAuthenticated } from '../../utils/isUserAuthenticated'
import { toggleIsExtensionDownloaded } from '../../utils/toggleIsExtensionDownloaded'
import { useAppState } from '../appState'
import { ROUTES } from '../constants'
import useBrandJoinFlow from '../hooks/useBrandJoinFlow'
import awsConfig from './../aws-exports'
import BlockUserInterface from './../components/blockUserInterface'
import GatePage from './gate'
import HomePage from './landing'

Amplify.configure(awsConfig)

interface IndexPageProps {
  data: {
    site: {
      siteMetadata: {
        title: string
      }
    }
  }
  t: TFunction
  location: LocationProps
}

const indexPage = (props: IndexPageProps) => {
  const [isPageLoading, setIsPageLoading] = useState(true)
  const [appState] = useAppState()
  const { checkJoinFlowExpiry } = useBrandJoinFlow()

  useEffect(() => {
    checkJoinFlowExpiry()

    isUserAuthenticated().then(async result => {
      const urlParams = new URLSearchParams(window.location.search)
      const isExtensionRemoved = urlParams.get('fromExtension') === 'false'

      if (!!result) {
        const signInFromExtension = urlParams.get('signInFromExtension')

        if (isExtensionRemoved) {
          // user is authenticated and has just removed the extension
          await toggleIsExtensionDownloaded(appState.userId, false)
        }

        navigate(
          `${ROUTES.member}${
            signInFromExtension
              ? '?signInFromExtension=signInFromExtension'
              : ''
          }`
        )
      } else {
        setIsPageLoading(false)
      }
    })
  }, [])

  if (isPageLoading) {
    return <BlockUserInterface />
  } else {
    if (appState.settings?.gateEnabled) {
      return <GatePage {...props} />
    } else {
      return <HomePage {...props} />
    }
  }
}

export default withTranslation()(indexPage)

export const query = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    file(relativePath: { eq: "chirpyest-mobile.png" }) {
      childImageSharp {
        fluid(maxWidth: 1024) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
